import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { useState } from 'react';




export default function QRcode({link}) {

	return (
			<>
				<br />
				<img src={link} />	
			</>
		)	
	
}