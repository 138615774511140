import React from "react";
import LinkInput from './components/LinkInput';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect } from 'react';




function App() {

	useEffect(() => {
		document.title = 'Short pobery!';
	}, []);

	return (
		<div className="App">
			<header className="App-header">
				<LinkInput />
			</header>
		</div>
	);
}

export default App;