import React, { Component } from 'react';



export default function Title() {
			
			


		return (
			<>
				<h1>Добавляй, сокращай!</h1>
			</>
		)	
	//}
	
}