import React, { Component } from 'react';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import QRcode from './QRcode';
import Toast from 'react-bootstrap/Toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


export default function ShortLink({short_link, qr_code_link, qr_code_await}) {
					
	const [show, setShow] = useState(false);
	
			
	let full_link = window.location.href + short_link;
	full_link = full_link.replace('https://', '');
	
	
	
	function LoadingSign({show}){
		
		return (
			<p>{show && 'QR код генерируется...'}</p>
		)

	}
	
	
	
	
	function QRcodeImg({link}, {show}){
		
		if (!show && !link){
			return ("");
		}
		
		if (link){
			return (<QRcode link={link} />);
		} else {
			return (<LoadingSign show={show} />);
		}
		
	}
	
	
	function handleFullLinkClick(e){
		navigator.clipboard.writeText(full_link); 
		setShow(true);
	}
	

	return (
			<>		
				<Card className="text-center">
					<Card.Header>Короткая ссылка:</Card.Header>
						<Card.Body>
							<Card.Title className="short-link-area" onClick={handleFullLinkClick}>{full_link}</Card.Title>							
								{qr_code_link ? <QRcode link={qr_code_link} /> : <LoadingSign show={qr_code_await} />}
						</Card.Body>
				</Card>
							
				<Toast className="mytoast" onClose={() => setShow(false)} show={show} delay={1000} autohide bg={'dark'}>
					<Toast.Body className="text-white">Скопировано!</Toast.Body>
				</Toast>
		
		
			</>
		)	
	
}