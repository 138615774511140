import React, { Component } from 'react';
import { useState } from 'react';
import { generateShortLink } from '../service/generator';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ShortLink from './ShortLink';
import Title from './Title';
import QRcode from './QRcode';



const BASE_URL = '';//'http://127.0.0.1:3000';
const SHORT_LINK_LENGTH = 4; //Количество символов в короткой ссылке
const API_PREFIX = '/api/';

const LinkType = {
				SHORT: 'checkshortlink',
				LONG: 'checklink'
			}





//Проверяем, есть ли такая полная ссылка уже в базе, если да - возвращаем короткую для нее
async function checkLongLinkExist(link_){
		
	let res = 'false';
	let link_for_check = link_; //.toLowerCase();  Ссылки могут зависеть от регистра
		
	try{			
		const response = await fetch(BASE_URL + API_PREFIX + LinkType.LONG + '?' + new URLSearchParams({link: link_for_check}));

		if (!response.ok) {
			throw new Error(`Error! status: ${response.status}`);
		}

		const result = await response.json();
				
		if (result.length != 0){
			res = result[0].short_link;
		}						
	} catch (err) {
		console.log(err.message);
	}			
	return (res);
			
}
		
		
		
		
		
//Проверяем, есть ли такая короткая ссылка уже в базе, если да - возвращаем true
async function checkShortLinkExist(link_){
		
	let res = false;
		
	try{			
		const response = await fetch(BASE_URL + API_PREFIX + LinkType.SHORT + '?' + new URLSearchParams({link: link_}));

		if (!response.ok) {
			throw new Error(`Error! status: ${response.status}`);
		}

		const result = await response.json();
				
		if (result.length == 0){
			res = true;
		}						
	} catch (err) {
		console.log(err.message);
	}			
	return (res);
}






async function getQRCode(link){
	
	const QR_CODE_SIZE = "200x200";
	const QR_CODE_API_LINK = "https://api.qrserver.com/v1/create-qr-code/?data=";
	
	let imageObjectURL = '';

	try{
		const response = await fetch(QR_CODE_API_LINK + link + '&size=' + QR_CODE_SIZE);
		const result = await response.blob();

		imageObjectURL = URL.createObjectURL(result);
		
	} catch (err) {
		console.log(err.message);
	}	
	
	return (imageObjectURL);

}








export default function LinkInput() {
			
	const [link, setLink] = useState(null);
	const [shortlink, setShortLink] = useState('');
	const [generateQR, setGenerateQR] = useState(false);
	const [QRcodeURL, setQRcodeURL] = useState(null);

			
	let short_link = '';
	
	
	
	
	
	async function handleQRclick(e){
		
		setGenerateQR(e.target.checked);
		
		if (QRcodeURL === null && link !== null){
			try{
				const QRCodeResult = await getQRCode(link);				
				setQRcodeURL(QRCodeResult);				
			} catch (err) {
				console.log(err);
			}			
		}
	}
	
	
			
			
	let handleSubmit = async (e) => {
				
		e.preventDefault();
				
		//Удаляем пробелы
		let fixed_link = link.replace(/\s/g, '')	
		setLink(fixed_link);
		
		//Если ссылка в принципе введена
		if (fixed_link != ''){
			
			//Проверяем полную ссылку
			const check = await checkLongLinkExist(fixed_link);
					
			//Если полной ссылки нет (false) - добавляем в базу
			if (check == 'false'){
						
				try {					
					let short_link_check = false;					
					
					//Генерируем короткую ссылку и проверяем, есть ли уже такая в базе. Генерируем пока не будет уникальной
					//
					//ДОБАВИТЬ: ограничить кол-во итераций, чтобы не было бесконечного цикла, когда база будет переполнена
					//
					while (short_link_check != true){
						short_link = generateShortLink(SHORT_LINK_LENGTH);
						short_link_check = await checkShortLinkExist(short_link);
					}
							
					
					let response = await fetch(BASE_URL + API_PREFIX + "addlink", {
						method: 'POST',
						headers: {
								'Content-Type': 'application/json;charset=utf-8'
						},
						body: JSON.stringify({
								link: fixed_link,
								short_link: short_link,
								date: new Date()
						}),
					});
			  
							
					let result = await response.text();
					
					if (response.status === 201) {
						setShortLink(result);
					}
				} catch (err) {
					console.log(err);
				}
			} 
			//Если такая полная ссылка уже в базе - возвращаем короткую для нее
			else {
				setShortLink(check);	
			}
			
			//Создаем QR код, если выбрана такая опция, иначе обнуляем ссылку, если что-то осталось с прошлой итерации
			if (generateQR){
				try{
					const QRCodeResult = await getQRCode(fixed_link);				
					setQRcodeURL(QRCodeResult);				
				} catch (err) {
					console.log(err);
				}
			} else {
				setQRcodeURL(null);
			}
			
		}
	};

	
		return (
			<>
				<Title />
				<br />
				<Form onSubmit={handleSubmit}>
					<Form.Label htmlFor="inputlink">введите ссылку</Form.Label>
						<Form.Control
							type="text"
							id="inputlink"
							//value={link}
							onChange={(e) => setLink(e.target.value)}
							aria-describedby="inputFullLink"
						/>
					<br />
					
					<div style={{fontSize: 20, marginLeft: "16%", textAlign: "left"}}>				
					<Form.Check 
						type="checkbox"
						id="qrCodeCheckBox"
						label="с QR кодом"
						onChange={handleQRclick}
					/>
					</div>
					
					<br />
					<Button size="lg" variant="outline-warning" type="submit">Короче!</Button>
				</Form>
				<br />
				{shortlink ? <ShortLink short_link={shortlink} qr_code_link={QRcodeURL} qr_code_await={generateQR}/> : null}
			</>
		)	

	
}