
const alphabet = "abcdefghijklmnopqrstuvwxyz"


function generateShortLink(iterations){
	
	let it = parseInt(iterations);
	let shortLink = '';
	
	for (let i = 0; i < it; i++){
		shortLink = shortLink + alphabet[Math.floor(Math.random() * alphabet.length)];
	}
	
	return shortLink;
}


module.exports = {
    generateShortLink
}